import { graphql, useStaticQuery } from "gatsby";
import { BecomeATipperPage } from "../model/become-a-tipper-page";

export const useBecomeATipperPage = (): BecomeATipperPage => {
  const { strapiWannaBecomeATipperPage } = useStaticQuery(graphql`
    query useBecomeATipperPage {
      strapiWannaBecomeATipperPage {
        title
        subtitile
        rightSideText
        leftSideText
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, height: 320)
          }
        }
        description
      }
    }
  `);
  
  return strapiWannaBecomeATipperPage;
};
