import React from "react";
import { BecomeATipper } from "../components/BecomeATipper/BecomeATipper";
import { Container } from "../components/Shared/Container/Container";
import Layout from "../components/Shared/Layout/Layout";

export interface IWantToBecomeATipperPageProps {
  className?: string;
}

export const IWantToBecomeATipperPage = (
  props: IWantToBecomeATipperPageProps
) => {
  const { className = "" } = props;
  return (
    <Layout className={`${className}`}>
      <BecomeATipper />
    </Layout>
  );
};

export default IWantToBecomeATipperPage;
