import { Link } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { routes } from "../../lib/routes";
import { Button } from "../Shared/Button/Button";
import { Container } from "../Shared/Container/Container";
import { ContentRenderer } from "../Shared/ContentRenderer/ContentRenderer";
import { SectionHeader } from "../Shared/SectionHeader/SectionHeader";
import { useBecomeATipperPage } from "./hooks/useBecomeATipperPage";

export interface BecomeATipperProps {
  className?: string;
}

export const BecomeATipper = (props: BecomeATipperProps) => {
  const { className = "" } = props;
  const page = useBecomeATipperPage();

  return (
    <section className={`${className} py-16`}>
      <Container>
        <SectionHeader
          className="text-center"
          title={page.title}
          subtitle={page.subtitile}
          description={page.description}
        />
        <article className="mt-16 flex space-y-10 lg:space-y-0 lg:space-x-10 lg:flex-row flex-col">
          <div className="left lg:w-1/2">
            <div className="image shadow-lg flex">
              <GatsbyImage
                className="h-full w-full"
                image={getImage(page.image as any)}
                alt="tipper image"
              />
            </div>
            <div className="detail mt-12">
              <ContentRenderer source={page.leftSideText} />
            </div>
          </div>
          <div className="right lg:w-1/2 flex flex-col justify-between">
            <ContentRenderer source={page.rightSideText} />
            <div className="actions lg:mt-0 mt-10">
              <Link to={routes.contact.to}>
                <Button>CHCEM DAŤ TIP NA PREDÁVAJÚCEHO</Button>
              </Link>
            </div>
          </div>
        </article>
      </Container>
    </section>
  );
};
